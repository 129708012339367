import * as React from "react"
import "./style.css"
import citationCorner from "../../images/citation_corner.svg"

const PhotoSectionTitle = ({ title }) => (
  <table className={"photo_section_title__table"}>
    <tr>
      <td className={"photo_section_title__cell"}>
        <img
          src={citationCorner}
          alt={""}
          height={30}
          width={30}
          className={
            "photo_section_title__corner photo_section_title__corner_up"
          }
        />
      </td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td>
        <h2 className="photo_section_title__text">{title}</h2>
      </td>
      <td></td>
    </tr>
    <tr>
      <td></td>
      <td></td>
      <td>
        <img
          src={citationCorner}
          alt={""}
          height={30}
          width={30}
          className={
            "photo_section_title__corner photo_section_title__corner_down"
          }
        />
      </td>
    </tr>
  </table>
)

export default PhotoSectionTitle
