import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import "./style.css"
import PhotoSectionTitle from "../PhotoSectionTitle/PhotoSectionTitle"
import PhotoGalleryElement from "../PhotoGalleryElement/PhotoGalleryElement"
import { useState } from "react"
import PhotoDetailModal from "../PhotoDetailModal/PhotoDetailModal"
import Button from "../Button/Button"

const PhotoGallerySection = ({
  title,
  photos,
  layout,
  realWidth,
  realHeight,
  right,
  onPhotoClick,
}) => {
  const dividedLayout = layout.split(
    "-".repeat(layout.split("\n")[0].length) + "\n"
  )
  console.log("divided layout length:"+dividedLayout.length.toString())
  layout = dividedLayout.join("")
  // layout = baseLayout + "\n" + moreLayout
  const height = layout.split("\n").length
  const width = layout.split("\n")[0].length
  const divisionsHeights = dividedLayout.map(division => division.split("\n").length - (division[division.length-1] === "\n" ? 1 : 0))
  const divisionsRealHeights = divisionsHeights.map(divisonHeight => (realHeight / height) * divisonHeight)

  const baseHeight = dividedLayout[0].split("\n").length-1
  const baseRealHeight = (realHeight / height) * baseHeight
  const gap = 20
  const smallgap = 5
  const divisionsElements = [
    "abcdefghijklmnopqrstuvwxyz".slice(
      0,
      countElements(dividedLayout[0])
    )
  ]
  let currentCount = countElements(dividedLayout[0])
  for (let i = 1; i < dividedLayout.length; i++) {
    divisionsElements.push(
      "abcdefghijklmnopqrstuvwxyz".slice(
        currentCount,
        currentCount + countElements(dividedLayout[i])
      )
    )
    currentCount += countElements(dividedLayout[i])
  }

  function countElements(layout) {
    const s = new Set()
    for (let char of layout) {
      if ("abcdefghijklmnopqrstuvwxyz".includes(char)) {
        s.add(char)
      }
    }
    return s.size
  }

  function getDimensionsOfElement(element) {
    let w = 0
    let h = 0
    const rows = layout.split("\n")
    for (let row of rows) {
      if (row.includes(element)) {
        w = row.match(new RegExp(element, "g")).length
        break
      }
    }
    // transpose the layout
    const transposed = rows[0]
      .split("")
      .map((_, colIndex) => rows.map(row => row[colIndex]))
      .map(col => col.join(""))
    for (let row of transposed) {
      if (row.includes(element)) {
        h = row.match(new RegExp(element, "g")).length
        break
      }
    }
    return { w, h }
  }

  function getPositionOfElement(element) {
    let x = 0
    let y = 0
    const rows = layout.split("\n")
    for (let row of rows) {
      if (row.includes(element)) {
        y = rows.indexOf(row)
        x = row.indexOf(element)
        break
      }
    }
    return { x, y }
  }

  const [moreDisplayed, setMoreDisplayed] = useState(false)
  const [sectionsDisplayed, setSectionsDisplayed] = useState(1)

  return (
    <div className="photo-gallery-section">
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: right ? "flex-end" : "flex-start",
        }}
      >
        <PhotoSectionTitle title={title} />
      </div>
      <div
        className="photo-gallery-section-layout"
        style={{
          position: "relative",
          height: divisionsRealHeights.slice(0, sectionsDisplayed).reduce((a,b) => a+b, 0),
          width: "60vw",
        }}
      >
        {divisionsElements.slice(0,sectionsDisplayed).join("").split("").map(element =>
          (() => {
            const { w, h } = getDimensionsOfElement(element)
            const { x, y } = getPositionOfElement(element)

            if (!photos["abcdefghijklmnopqrstuvwxyz".indexOf(element)]) {
              return <div></div>
            }

            const photo = getImage(
              photos["abcdefghijklmnopqrstuvwxyz".indexOf(element)].asset
            )
            return (
              <PhotoGalleryElement
                height={
                  (h / height) * realHeight -
                  (y + h !== height
                    ? window.innerWidth > 1200
                      ? gap
                      : smallgap
                    : 0)
                }
                width={
                  (w / width) * realWidth -
                  (x + w !== width
                    ? window.innerWidth > 1200
                      ? gap
                      : smallgap
                    : 0)
                }
                left={(x / width) * realWidth}
                top={(y / height) * realHeight}
                photo={photo}
                onClick={() => {
                  onPhotoClick(photo)
                }}
              />
            )
          })()
        )}
      </div>
      <div className={"view-more-btn"}>
        <Button
          text={sectionsDisplayed === dividedLayout.length ? "Afficher Moins" : "Afficher Plus"}
          theme={"dark"}
          onClick={() => setSectionsDisplayed(sectionsDisplayed === dividedLayout.length ? 1 : sectionsDisplayed + 1)}
        />
      </div>
    </div>
  )
}

export default PhotoGallerySection
