import * as React from "react"
import { useState } from "react"
import Vignette from "../Vignette/Vignette"
import Bandeau from "../Bandeau/Bandeau"
import { toPlainText } from "@portabletext/react"
import {graphql} from "gatsby";

const VideoGallerySection = ({ element, index, onVignetteClicked }) => {
  const [openSections, setOpenSections] = useState(new Set())
  const addOpenSection = index => {
    setOpenSections(new Set(openSections.add(index)))
  }

  const removeOpenSection = index => {
    setOpenSections(new Set(new Array(openSections).filter(i => i !== index)))
  }

  return (
    <section>
      <Bandeau
        titre={element.title}
        textButton={openSections.has(index) ? "Voir moins" : "Voir plus"}
        video={element.backgroundVideo?.videoId || "PgSDKGPq62g"}
        onShowMore={() => {
          if (openSections.has(index)) {
            removeOpenSection(index)
          } else {
            addOpenSection(index)
          }
        }}
      />
      <div
        className={
          openSections.has(index) ? "cadreVignettes" : "cadreVignettes noheight"
        }
        style={{
          maxHeight: openSections.has(index)
            ? element.videos.length * 600
            : 0,
        }}
      >
        {element.videos.map(elem => (
          <Vignette
            titre={elem.title}
            sousTitre={elem.thumbnailSubtitle}
            video={elem.video.videoId}
            detailSousTitre={elem.detailsSubtitle}
            description={elem._rawDescription}
            equipment={elem.equipment.map(equip => equip.title)}
            onVignetteClicked={onVignetteClicked}
          />
        ))}
      </div>
    </section>
  )
}


export default VideoGallerySection
