import React from "react"
import "./style.css"
import Button from "../Button/Button"
import ReactMarkdown from "react-markdown"
import YouTube from "react-youtube";
import {PortableText} from "@portabletext/react";

const unlockScroll = () => {
  window.onscroll = null
}

const DetailModal = ({
  open,
  onClose,
  titre,
  detailSousTitre,
  description,
  videoUrl,
  equipment,
  videoTagRef,
}) => {
  const [videoElement, setVideoElement] = React.useState(null)
  return (
    <div
      className={
        open ? "detail_modal__veil" : "detail_modal__veil not-displayed"
      }
    >
      <div className="detail_modal__modal">
        <div className="detail_modal__titles_box">
          <h1 className="detail_modal__title">{titre}</h1>
          <h3 className="detail_modal__subtitle">{detailSousTitre}</h3>
        </div>
        <div className="detail_modal__video_container">
          <YouTube
            videoId={videoUrl}
            className={'detail_modal__video'}
            onReady={(event) => {
              setVideoElement(event.target)
            }}
          />
        </div>
        <div className="detail_modal__description">
          <PortableText value={description} />
        </div>
        {/*<div className="detail_modal__separator_line" />*/}
        {/*<div className="detail_modal__equipment">*/}
        {/*  <p>MATERIEL:</p>*/}
        {/*  <br />*/}
        {/*  {equipment.map(equip => (*/}
        {/*    <p>{equip}</p>*/}
        {/*  ))}*/}
        {/*</div>*/}
        <Button
          text={"Fermer"}
          theme={"dark"}
          onClick={() => {
            videoElement.pauseVideo()
            unlockScroll()
            onClose()
          }}
        />
      </div>
    </div>
  )
}

export default DetailModal
