import React, {useState} from "react"
import "./style.css"
import Button from "../Button/Button"
import YouTube from "react-youtube";

const lockScroll = () => {
  const scrollY = window.scrollY
  const scrollX = window.scrollX
  window.onscroll = () => {
    window.scrollTo(scrollX, scrollY)
  }
}

function Vignette({
                    titre,
                    sousTitre,
                    video,
                    detailSousTitre,
                    description,
                    equipment,
                    onVignetteClicked,
                  }) {
  const [player, setPlayer] = useState(null)

  return (
    <div className={"cadreVignette"}>
      <div
        className={"videoVignette hover3d"}
        onMouseEnter={() => {
          if (player) {
            player.playVideo()
          }
        }}
        onMouseLeave={() => {
          if (player) {
            player.pauseVideo()
          }
        }}
      >
        <YouTube
          videoId={video}
          opts={{
            playerVars: {
              controls: 0,
              loop: 1,
              playlist: video,
              rel: 0,
            },
          }}
          className={'videoVignetteIframe'}
          onReady={(event) => {
            setPlayer(event.target)
          }}
        />
        <div className={"videoVignetteVeil"}></div>
      </div>
      <div className={"textVignette"}>
        <h1 className={"titreVignette"}>{titre}</h1>
        <h2 className={"sousTitreVignette"}>{sousTitre}</h2>
        <Button
          className={"buttonVignette"}
          text={"Voir la fiche détaillée"}
          theme={"little"}
          onClick={() =>
            onVignetteClicked({
              titre,
              detailSousTitre,
              description,
              equipment,
              video,
            })
          }
        />
      </div>
    </div>
  )
}

export default Vignette
