import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PhotoGalleryElement = ({ top, left, width, height, photo, onClick }) => {
  return (
    <>
      <div onClick={onClick}>
        <GatsbyImage
          style={{
            position: "absolute",
            top: top,
            left: left,
            width: width,
            height: height,
            userSelect: "none",
          }}
          image={photo}
          alt={""}
          className={"hover3d"}
        />
      </div>
    </>
  )
}

export default PhotoGalleryElement
