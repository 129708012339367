import React, {useEffect, useRef, useState} from "react"
import "./galerie.css"
import PageHeader from "../components/PageHeader/PageHeader"
import PageFooter from "../components/PageFooter/PageFooter"

import {graphql, useStaticQuery} from "gatsby"
import VideoGallerySection from "../components/VideoGallerySection/VideoGallerySection"
import DetailModal from "../components/DetailModal/DetailModal"
import Seo from "../components/seo"
import PhotoGallerySection from "../components/PhotoGallerySection/PhotoGallerySection";
import PhotoDetailModal from "../components/PhotoDetailModal/PhotoDetailModal";

const lockScroll = () => {
  const scrollY = window.scrollY
  const scrollX = window.scrollX
  window.onscroll = () => {
    window.scrollTo(scrollX, scrollY)
  }
}

const Galerie = () => {
  const [currentTab, setCurrentTab] = React.useState("video")
  const [photoModalOpen, setPhotoModalOpen] = useState(false)
  const [videoModalOpen, setVideoModalOpen] = useState(false)
  const [currentPhoto, setCurrentPhoto] = useState(null)
  const [currentVideo, setCurrentVideo] = useState(null)
  const videoTagRef = useRef(null)

  const [underlinePosition, setUnderlinePosition] = React.useState([0, 1000, 0])
  const navbarLeftElement = useRef()
  const navbarRightElement = useRef()

  const updateUnderlinePosition = () => {
    if (!{video: navbarLeftElement, photo: navbarRightElement}[currentTab] || !{video: navbarLeftElement, photo: navbarRightElement}[currentTab].current) {
      return
    }
    const x = {video: navbarLeftElement, photo: navbarRightElement}[
      currentTab
      ].current.offsetLeft
    const y =
      {video: navbarLeftElement, photo: navbarRightElement}[currentTab]
        .current.offsetTop + 65
    const w = {video: navbarLeftElement, photo: navbarRightElement}[
      currentTab
      ].current.getBoundingClientRect().width
    setUnderlinePosition([x, y, w])
  }

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        updateUnderlinePosition()
      },
      true
    )
  }, [])

  useEffect(() => {
    updateUnderlinePosition()
  }, [currentTab])

  const {sanityVideoGallery, sanityPhotogallery} = useStaticQuery(query)
  const {header, sections: videoSections} = sanityVideoGallery
  const { header: HeaderPhoto, sections } = sanityPhotogallery

  console.log(videoSections)


  useEffect(() => {
    videoTagRef.current?.load()
  }, [currentVideo])

  return (
    <div style={{paddingTop: "100vh"}}>
      <Seo title="Galerie"/>
      <PageHeader
        titre={"Galerie"}
        sousTitre={`Accueil • Galerie ${currentTab === "video" ? "Vidéo" : "Photo"}`}
        media={currentTab === "video" ? header : HeaderPhoto}
      />
      <div className={"navbar"}>
        <div
          className={"navbar-item"}
          ref={navbarLeftElement}
          onClick={() => setCurrentTab("video")}
        >
          <p>Vidéos</p>
        </div>
        <div
          className={"navbar-item"}
          ref={navbarRightElement}
          onClick={() => {
            setCurrentTab("photo")
          }}
        >
          <p>Photos</p>
        </div>
        <div
          className={"navbar-underline"}
          style={{
            left: underlinePosition[0],
            top: underlinePosition[1],
            width: underlinePosition[2],
          }}
        />
      </div>
      <div
        className={currentTab === "video" ? "galerie-center" : "galerie-right"}
      >
        {videoSections.map((element, index) => (
          <VideoGallerySection
            key={index}
            element={element}
            index={index}
            onVignetteClicked={video => {
              lockScroll()
              setCurrentVideo(video)
              setVideoModalOpen(true)
            }}
          />
        ))}
      </div>
      <div
        className={currentTab === "photo" ? "galerie-center" : "galerie-left"}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100vw",
            alignItems: "center",
            marginBottom: 50,
          }}
        >
            {sections.map((section, index) => {
              console.log("section height: "+section.layout.filter(child => child && !child._rawChildren[0].text.includes("-")).length.toString())
              return (
                <section>
                  {typeof window !== "undefined" && (
                    <PhotoGallerySection
                      title={section.title}
                      photos={section.photos}
                      layout={section.layout.map(child => child._rawChildren[0].text).join("\n")}
                      realHeight={
                        (window.innerWidth > 1200
                          ? window.innerWidth * 0.6
                          : window.innerWidth * 0.9) *
                        (section.layout.filter(child => child && !child._rawChildren[0].text.includes("-")).length /
                          section.layout[0]._rawChildren[0].text.length)
                      }
                      realWidth={
                        window.innerWidth > 1200
                          ? window.innerWidth * 0.6
                          : window.innerWidth * 0.9
                      }
                      right={index % 2 === 1}
                      onPhotoClick={photo => {
                        lockScroll();
                        setCurrentPhoto(photo);
                        setPhotoModalOpen(true);
                      }}
                    />
                  )}
                </section>
              );
            })}
          </div>
      </div>
      <PhotoDetailModal
        photo={currentPhoto}
        open={photoModalOpen}
        onClose={() => setPhotoModalOpen(false)}
      />
      {currentVideo !== null && (
        <DetailModal
          open={videoModalOpen}
          onClose={() => setVideoModalOpen(false)}
          titre={currentVideo.titre}
          detailSousTitre={currentVideo.detailSousTitre}
          description={currentVideo.description}
          videoUrl={currentVideo.video}
          equipment={currentVideo.equipment}
          videoTagRef={videoTagRef}
        />
      )}
      <PageFooter/>
    </div>
  )
}

const query = graphql`
    query {
        sanityVideoGallery{
            header {
                ...HeaderImage
                ...HeaderYoutubeVideo
            }
            sections {
                title
                backgroundVideo {
                    videoId
                }
                videos {
                    _rawDescription
                    detailsSubtitle
                    thumbnailSubtitle
                    title
                    equipment {
                        title
                    }
                    video {
                        videoId
                    }
                }
            }
        }
        sanityPhotogallery {
            header {
                ...HeaderYoutubeVideo
                ...HeaderImage
            }
            sections {
                title
                photos {
                    asset {
                        gatsbyImageData(width: 1920, placeholder: BLURRED)
                    }
                }
                layout {
                    _rawChildren
                }
            }
        }
    }
`

export const Head = () => <Seo title="Galerie"/>

export default Galerie
