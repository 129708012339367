import React from "react"
import "./style.css"
import Button from "../Button/Button"
import YouTube from "react-youtube";

function Bandeau({ titre, textButton, video, onShowMore }) {
  return (
    <div className={"bandeau"}>
      <YouTube
        videoId={video}
        opts={{
          playerVars: {
            autoplay: 1,
            mute: 1,
            controls: 0,
            loop: 1,
            playlist: [video]
          }
        }}
        id="backgroundVideo"
        className={"bandeau__video"}
        onReady={event => event.target.playVideo()}
        onError={event => event.target.playVideo()}
      />
      <div className={"bandeau__veil"}>
        <div className={"textBandeau"}>
          <h1 className={"titreBandeau"}>{titre}</h1>
          <Button
            className={"buttonBandeau"}
            text={textButton}
            onClick={onShowMore}
          />
        </div>
      </div>
    </div>
  )
}

export default Bandeau
