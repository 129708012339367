import React from "react"
import "./style.css"
import Button from "../Button/Button"
import { GatsbyImage } from "gatsby-plugin-image"

const unlockScroll = () => {
  window.onscroll = null
}

const PhotoDetailModal = ({ open, onClose, photo }) => {
  return (
    <div
      className={
        open
          ? "photo_detail_modal__veil"
          : "photo_detail_modal__veil not-displayed"
      }
    >
      <div className="photo_detail_modal__modal">
        <GatsbyImage
          alt={""}
          image={photo}
          className={"photo_detail_modal__photo"}
          objectFit={`contain`}
        />
        <Button
          text={"Fermer"}
          theme={"dark"}
          onClick={() => {
            unlockScroll()
            onClose()
          }}
        />
      </div>
    </div>
  )
}

export default PhotoDetailModal
